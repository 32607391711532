import { connect, useDispatch } from "react-redux";
import { Breadcrumb, Button, Drawer } from "flowbite-react";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { HiBeaker, HiExternalLink, HiEye, HiFire, HiHome, HiPencil, HiPlus, HiSave, HiX } from "react-icons/hi";
import { GiComb } from "react-icons/gi";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  EditableBadgeField,
  EditableLinkField,
  EditableMultiLinkField,
  EditableTextField,
} from "../components/editableField";
import Layout from "../components/layout";
import { AuthContext } from "../context/AuthContext";
import {
  useFetchStatuses,
  fetchStack,
  fetchUsers,
  useFetchVacancyDetail,
  useJobRelatedActions,
  useJobVacancyActions,
  fetchPartners,
  useFetchPartnerDetail,
  useFetchPartners,
} from "../hooks/useApi";
import {
  Box,
  IconButton,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  Tooltip,
  Typography,
  Button as JoyButton
} from "@mui/joy";
import { useLoading } from "../context/LoadingContext";
import Kanban from "../components/kanban";
import GenericTable from "../components/tableAdmin";
import { vacanciesActions } from "../slices/vacancies";
import { BASE_NEW_RATE_TYPE } from "../config";
import { statusesActions } from "../slices/statuses";
import { stacksActions } from "../slices/stacks";
import { partnersActions } from "../slices/partners";
import { processingsActions } from "../slices/processings";
import { comment } from "postcss";
import { ListItemText } from "@mui/material";
import { scoringCriteriasActions } from "../slices/scoringCriteria";
import { scoringsActions } from "../slices/scorings";
import { rateTypesActions } from "../slices/rateTypes";
import { useCustomSearchParams } from "../components/utils";
import axios from "axios";
import { apiRequest } from "../services/api";


const VacancyDetail = (props) => {
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [isCreated, setIsCreated] = useState(false);
  const [gptResult, setGptResult] = useState(null);

  const [searchObject, searchParams, setSearchParams] = useCustomSearchParams();
  const id = searchParams.get('id');
  const view = searchParams.get('view');

  const [ processingModalOpen, setProcessingModalOpen ] = useState(false);

  async function handleSearchStacks(query) {
    return props.stacks.entities.filter((stack) =>
      stack.name.toLowerCase().includes(query.toLowerCase()),
    );
  }

  async function handleSearchPartner(query, filter) {
    return props.partners.entities.filter(
      (partner) =>
        partner.name.includes(query) &&
        (filter ? filter === partner.type.id : true),
    );
  }

  async function handleCreateStacks(query) {
    dispatch(props.stacksActions.create({data: {name: query}}));
  }

  async function handleUpdateRateType(query) {
    dispatch(props.vacanciesActions.updateState({target: "entity", data: {
      ...props.vacancies.entity,
      rate_type: props.rateTypes.entities.find(s => s.id === parseInt(query))
    }}));
    if (props.vacancies.entity.id) {
      dispatch(props.vacanciesActions.update({id: props.vacancies.entity.id, data: {rate_type: props.rateTypes.entities.find(s => s.id === parseInt(query))}}));
    }
  }

  async function handleUpdateVacancy(query, field) {
    dispatch(props.vacanciesActions.updateState({target: "entity", data: {
      ...props.vacancies.entity,
      [field]: query
    }}));
    if (props.vacancies.entity.id) {
      dispatch(props.vacanciesActions.update({id: props.vacancies.entity.id, data: {[field]: query}}));
    }
  }

  async function handleSelectStacks(query) {
    dispatch(props.vacanciesActions.updateState({target: "entity", data: {
      ...props.vacancies.entity,
      stacks: query
    }}));
    if (props.vacancies.entity.id) {
      dispatch(props.vacanciesActions.update({id: props.vacancies.entity.id, data: {stacks: query.map((q) => q.id)}}));
    }
  }

  async function handleCombDescription() {
    setGptResult("loading");
    const result = await apiRequest('/utils/openai', 'POST', {data : {
      requestType: 'job_description',
      message: props.vacancies.entity.description,
    }}, {
      'Content-Type': 'application/json'
    });
    setGptResult(
      `Описание: ${result.data.project_description}\n  
${result.data.requirements && result.data.requirements.mandatory && result.data.requirements.mandatory && "Обязательные требования:"}
${result.data.requirements && result.data.requirements.mandatory && result.data.requirements.mandatory.map(m => "- "+m).join("\n")}\n\
${result.data.requirements && result.data.requirements.nice_to_have && result.data.requirements.nice_to_have && "Будет плюсом:"}
${result.data.requirements && result.data.requirements.nice_to_have.map(m => "- "+m).join("\n")}\n\
${result.data.responsibilities && "Задачи:"}
${result.data.responsibilities && result.data.responsibilities.map(m => "- "+m).join("\n")}`
    );
  };

  async function handleSelectRelated(query, field) {
    dispatch(props.vacanciesActions.updateState({target: "entity", data: {
      ...props.vacancies.entity,
      [field]: query
    }}));
    if (props.vacancies.entity.id) {
      dispatch(props.vacanciesActions.update({id: props.vacancies.entity.id, data: {[field]: query?.id ? query.id : query}}));
    }
  }

  async function handleCreateVacancy() {
    if (props.vacancies.entity.id) return;
    if (props.vacancies.entity.title === "Новая вакансия") {
      window.alert("Название вакансии обязательно!");
      return;
    }
    if (props.vacancies.entity.rate_type.name === "NULL") {
      window.alert("Укажите тип рейта вакансии");
      return;
    }
    if (!props.vacancies.entity.client) {
      window.alert("Укажите клиента");
      return;
    }
    if (!props.vacancies.entity.partner) {
      window.alert("Укажите партнера");
      return;
    }
    if (props.vacancies.entity.stacks.length === 0) {
      window.alert("Укажите стэк вакансии");
      return;
    }

    dispatch(props.vacanciesActions.create({data: props.vacancies.entity}))
    setIsCreated(true);
  }

  useEffect(()=>{
    if (!props.vacancies.loading && isCreated) {
      setSearchParams({
        view: "vacancy",
        id: props.vacancies?.entity.id
      })
    }
    setIsCreated(false);
  }, [props.vacancies?.entity]);

  useEffect(()=>{
    if (id === 'new' && !props.vacancies.entity?.status) {
      dispatch(props.vacanciesActions.updateState({target: "entity", data: {
        ...props.vacancies.entity,
        status: props.statuses.entities.find(status => status.relation === "job_vacancy" && status.weight === 0)
      }}))
    }
  }, [props.statuses.entities])

  useEffect(()=>{
    if (view === "vacancy" && id) {
      console.warn("fetch vacancy data", props);
      if ((!props.statuses.entities || props.statuses.entities.length === 0) && !props.statuses.loading) dispatch(props.statusesActions.getAll());
      if ((!props.rateTypes.entities || props.rateTypes.entities.length === 0) && !props.rateTypes.loading) dispatch(props.rateTypesActions.getAll());
      if ((!props.stacks.entities || props.stacks.entities.length === 0) && !props.stacks.loading) dispatch(props.stacksActions.getAll());
      if ((!props.partners.entities || props.partners.entities.length === 0) && !props.partners.loading) dispatch(props.partnersActions.getAll());
      if ((!props.processings.entities || props.processings.entities.length === 0) && !props.processings.loading) dispatch(props.processingsActions.getAll());
      if (id != "new") {
        if (!props.vacancies.entity || props.vacancies.entity?.id != parseInt(id)) dispatch(props.vacanciesActions.get({id: id}));
      } else {
        dispatch(props.vacanciesActions.updateState({target: "entity", data: {
          title: "Новая вакансия",
          description: "Описание",
          alter_description: "",
          min_salary_rate: 0,
          max_salary_rate: 0,
          rate_type: { id: BASE_NEW_RATE_TYPE },
          vacancy_type: "Fulltime",
          project: "Проект",
          project_length: "Бессрочний",
          priority: 2,
          location: "РФ",
          citizenship: "РФ",
          format: "Remote",
          status: props.statuses.entities.find(status => status.relation === "job_vacancy" && status.weight === 0),
          author: user.partner,
          client: null,
          partner: null,
          contractor: null,
          stacks: [],
          processing: [],
        }}))
      }
    };
  }, [id]);

  

  return (
    props.vacancies.entity && (
      <Drawer
        open={view === "vacancy" && id ? true : false}
        onClose={() => {
          if (searchParams.get('search')) {
            setSearchParams({search: searchParams.get('search')})
          } else { setSearchParams();}
          dispatch(props.vacanciesActions.updateState({target: "entity", data: null}))}}
        position="right"
        className="w-11/12 md:w-2/3 mt-[var(--Header-height)] md:mt-0"
        style={{
          marginTop: {
            xs: "var(--Header-height)",
            sm: 0,
          },
        }}
      >
        <div className="grid grid-cols-5 justify-items-stretch place-items-center">
          <Typography className="col-span-3 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            <EditableTextField
              value={props.vacancies.entity?.title}
              onApply={(v) => {
                handleUpdateVacancy(v, "title");
              }}
            />
          </Typography>
          <Box className="w-full col-span-2">
            {props.vacancies.entity?.id ? (
              <div className="flex flex-row gap-3 justify-end items-center">
                <Typography
                  level="body-sm"
                  gutterBottom
                  className="text-gray-500"
                >
                  Дата публикации:{" "}
                  {new Date(props.vacancies.entity?.created_at).toLocaleDateString("ru-RU", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>
                <IconButton 
                  size="sm" 
                  variant="soft"
                  onClick={() => {
                    window.open(`${window.location.origin}/jobs?query=${props.vacancies.entity?.job_hash}`, '_blank')
                  }}  
                >
                  <HiEye/>
                </IconButton>
              </div>
            ) : (
              <IconButton
                size="lg"
                variant="solid"
                onClick={() => handleCreateVacancy()}
              >
                <HiSave />
              </IconButton>
            )}
          </Box>
        </div>
        <div className="pt-8">
          <div className="rounded-lg bg-white p-4 shadow sm:p-6 xl:p-8 space-y-2">
            <div>
              <h6 className="text-lg text-gray-900">Детали вакансии</h6>
              <List>
                <ListItem>
                  <ListItemContent>
                    <EditableLinkField
                      value={props.vacancies.entity?.author?.id ? props.vacancies.entity?.author : null}
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Владелец
                        </Typography>
                      }
                      onSearch={(v) => handleSearchPartner(v)}
                      onApply={(v) => handleSelectRelated(v, 'author')}
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableTextField
                      value={props.vacancies.entity?.external_id}
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Внешний ID
                        </Typography>
                      }
                      onApply={(v) => handleUpdateVacancy(v, 'external_id')}
                      className="text-sm"
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableBadgeField
                      value={props.vacancies.entity?.status?.id}
                      badges={props.statuses.entities.filter(status => status.relation === "job_vacancy")}
                      onApply={(v) => handleSelectRelated({id: v}, 'status')}
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Статус
                        </Typography>
                      }
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableBadgeField
                      value={props.vacancies.entity?.priority}
                      badges={[
                        {
                          id: 1,
                          color: "failure",
                          name: (
                            <div className="flex flex-row place-items-center space-x-1">
                              <HiFire color="red" />
                              Высокий
                            </div>
                          ),
                        },
                        { id: 2, color: "warning", name: "Средний" },
                        { id: 3, color: "info", name: "Низкий" },
                      ]}
                      onApply={(v) => handleUpdateVacancy(v, "priority")}
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Приоритет
                        </Typography>
                      }
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableMultiLinkField
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Стэк
                        </Typography>
                      }
                      value={props.vacancies.entity?.stacks}
                      onSearch={(v) => handleSearchStacks(v)}
                      onCreate={handleCreateStacks}
                      onApply={(v) => handleSelectStacks(v)}
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableBadgeField
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Тип рейта
                        </Typography>
                      }
                      value={props.vacancies.entity?.rate_type?.id}
                      onApply={(v) => handleUpdateRateType(v)}
                      badges={props.rateTypes.entities.map(rt => {return {
                        id: rt.id, 
                        color: "info",
                        name: rt.name,
                      }})}
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableTextField
                      numeric
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Минимальный рейт
                        </Typography>
                      }
                      value={props.vacancies.entity?.min_salary_rate}
                      onApply={(v) => {
                        handleUpdateVacancy(v, "min_salary_rate");
                      }}
                      className="text-sm"
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableTextField
                      numeric
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Максимальный рейт
                        </Typography>
                      }
                      value={props.vacancies.entity?.max_salary_rate}
                      onApply={(v) => {
                        handleUpdateVacancy(v, "max_salary_rate");
                      }}
                      className="text-sm"
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableLinkField
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Клиент
                        </Typography>
                      }
                      value={props.vacancies.entity?.client?.id ? props.vacancies.entity?.client : null}
                      onSearch={(v) => handleSearchPartner(v)}
                      onApply={(v) => handleSelectRelated(v, 'client')}
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableLinkField
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Партнер
                        </Typography>
                      }
                      value={props.vacancies.entity?.partner?.id ? props.vacancies.entity?.partner : null}
                      onSearch={(v) => handleSearchPartner(v)}
                      onApply={(v) => handleSelectRelated(v, 'partner')}
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableLinkField
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Исполнитель
                        </Typography>
                      }
                      value={props.vacancies.entity?.contractor?.id ? props.vacancies.entity?.contractor : null}
                      onSearch={(v) => handleSearchPartner(v)}
                      onApply={(v) => handleSelectRelated(v, 'contractor')}
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableTextField
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Локация
                        </Typography>
                      }
                      value={props.vacancies.entity?.location}
                      onApply={(v) => {
                        handleUpdateVacancy(v, "location");
                      }}
                      className="text-sm"
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableTextField
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Гражданство
                        </Typography>
                      }
                      value={props.vacancies.entity?.citizenship}
                      onApply={(v) => {
                        handleUpdateVacancy(v, "citizenship");
                      }}
                      className="text-sm"
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableTextField
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Формат работы
                        </Typography>
                      }
                      value={props.vacancies.entity?.format}
                      onApply={(v) => {
                        handleUpdateVacancy(v, "format");
                      }}
                      className="text-sm"
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableTextField
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Тип занятости
                        </Typography>
                      }
                      value={props.vacancies.entity?.vacancy_type}
                      onApply={(v) => {
                        handleUpdateVacancy(v, "vacancy_type");
                      }}
                      className="text-sm"
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableTextField
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Проект клиента
                        </Typography>
                      }
                      value={props.vacancies.entity?.project}
                      onApply={(v) => {
                        handleUpdateVacancy(v, "project");
                      }}
                      className="text-sm"
                    />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    <EditableTextField
                      labelComponent={
                        <Typography
                          level="body-sm"
                          gutterBottom
                          className="text-gray-500"
                        >
                          Срок проекта
                        </Typography>
                      }
                      value={props.vacancies.entity?.project_length}
                      onApply={(v) => {
                        handleUpdateVacancy(v, "project_length");
                      }}
                      className="text-sm"
                    />
                  </ListItemContent>
                </ListItem>
              </List>
            </div>
            <div>
              <div className="flex flex-row gap-3">
                <h6 className="text-lg text-gray-900">Описание вакансии от клиента</h6>
              </div>
              <div className="text-md text-gray-900 pt-2">
                <EditableTextField
                  multiline={true}
                  value={props.vacancies.entity?.description}
                  onApply={(v) => handleUpdateVacancy(v, "description")}
                  className="text-sm"
                />
              </div>
            </div>
            <div>
              <div className="flex flex-row gap-3">
                <h6 className="text-lg text-gray-900">Публичное описание</h6>
                {props.vacancies.entity?.description && <Tooltip variant="soft" title="Причесать" placement="right" arrow>
                  <IconButton
                    loading={gptResult==="loading"}
                    onClick={handleCombDescription} 
                  >
                    <GiComb />
                  </IconButton>
                </Tooltip>}
              </div>
              <Box className="border-2 rounded-md p-4 w-full flex flex-col" sx={{
                display: gptResult && gptResult !== "loading" ? 'block' : 'none'
              }}>  
                <Typography level="body-sm">Предлагаемый результат:</Typography>
                <EditableTextField
                  activeDefault={true}
                  multiline={true}
                  value={gptResult}
                  onApply={(v) => {handleUpdateVacancy(v, "alter_description"); setGptResult(null)}}
                  onCancel={() => setGptResult(null)}
                  className="text-sm"
                />
              </Box>
              <div className="text-md text-gray-900 pt-2">
                <EditableTextField
                  multiline={true}
                  value={props.vacancies.entity?.alter_description}
                  onApply={(v) => handleUpdateVacancy(v, "alter_description")}
                  className="text-sm"
                />
              </div>
            </div>
          </div>
        </div> 
        {props.vacancies.entity?.id && <div className="pt-8">
          <div className="rounded-lg bg-white p-4 shadow sm:p-6 xl:p-8 space-y-2">
            <div>
              <h6 className="text-lg text-gray-900">Процессинг кандидатов</h6>
            </div>
            {!processingModalOpen && <GenericTable
                data={props.processings.entities.filter(processing => processing?.vacancy?.id === parseInt(id))}
                columns={[
                    {name: "employee", alias: "Кандидат", 
                      mod: (obj_id, value) => (
                        <EditableLinkField
                          blocked 
                          value={value}
                          onApply={async (v) => {
                            dispatch(props.processingsActions.update({id: obj_id, data: {employee: v}}));
                          }}
                          onSearch={handleSearchPartner}
                        />
                      )
                    },
                    {name: "recruitment", alias: "Процесс найма",
                      mod: (obj_id, value) => (
                        <EditableLinkField 
                          blocked
                          value={value}
                          onApply={async (v) => {
                            dispatch(props.processingsActions.update({id: obj_id, data: {recruitment: v}}));
                          }}
                          onSearch={() => {}}
                        />
                      )
                    },
                    {name: "status", alias: "Статус", order: "weight",
                      mod: (obj_id, value) => (
                        <EditableBadgeField
                          value={value.id}
                          badges={props.statuses.entities.filter(status => status.relation === "processing")}
                          onApply={async (v) => {
                            dispatch(props.processingsActions.update({id: obj_id, data: {status: v}}));
                          }}
                        />
                      )
                    },
                    {name: "comment", alias: "Комментарий",
                      mod: (obj_id, value) => (
                        <EditableTextField 
                          value={value}
                          onApply={async (v) => {
                            dispatch(props.processingsActions.update({id: obj_id, data: {comment: v}}));
                          }}
                        />
                      )
                    }
                ]}
                orderableColumns={["status"]}
                onDelete={async (v) => {
                  dispatch(props.processingsActions.drop({id: v}));
                }}
            />}        
          </div>
        </div>}
        {props.vacancies.entity?.id && <div className="pt-8">
          <div className="rounded-lg bg-white p-4 shadow sm:p-6 xl:p-8 space-y-2">
            <div>
              <h6 className="text-lg text-gray-900">Скорринги</h6>
            </div>
            <List>
              <ListItem>
                <ListItemContent>
                  <Box className="flex flex-row items-center gap-2">
                    <Typography>Скорринги от клиента</Typography>
                    <IconButton onClick={() => {window.open(`/partners?view=partner&id=${props.vacancies.entity.client.id}`, '_blank')}}><HiExternalLink/></IconButton>  
                  </Box>
                  Скоро будет
                </ListItemContent>
              </ListItem>
              <ListDivider />
              <ListItem>
                <ListItemContent>
                  <Box className="flex flex-row items-center gap-2">
                    <Typography>Скорринги от партнера</Typography>
                    <IconButton onClick={() => {window.open(`/partners?view=partner&id=${props.vacancies.entity.partner.id}`, '_blank')}}><HiExternalLink/></IconButton>  
                  </Box>
                  Скоро будет
                </ListItemContent>
              </ListItem>
              <ListDivider />
              <ListItem>
                <ListItemContent>
                  <Box className="flex flex-row items-center gap-2">
                    <Typography>Скорринги вакансии</Typography>
                    <IconButton disabled onClick={() => {window.open(`/partners?view=partner&id=${props.vacancies.entity.partner.id}`, '_blank')}}><HiPlus/></IconButton>
                    <IconButton disabled onClick={() => {window.open(`/partners?view=partner&id=${props.vacancies.entity.partner.id}`, '_blank')}}><HiBeaker/></IconButton>  
                  </Box>
                  Скоро будет
                </ListItemContent>
              </ListItem>
            </List>
          </div>
        </div>}
      </Drawer> 
    )
  );
};

const mapStateToProps = (state) => {
  return {
    vacancies: state.vacancies,
    statuses: state.statuses,
    stacks: state.stacks,
    partners: state.partners,
    processings: state.processings,
    scorings: state.scorings,
    rateTypes: state.rateTypes,
    scoringCriterias: state.scoringCriterias,
    rateTypesActions: rateTypesActions,
    vacanciesActions: vacanciesActions,
    statusesActions: statusesActions,
    stacksActions: stacksActions,
    partnersActions: partnersActions,
    processingsActions: processingsActions,
    scoringsActions: scoringsActions,
    scoringCriteriasActions:scoringCriteriasActions,
  };
};

export default connect(mapStateToProps)(VacancyDetail);